// extracted by mini-css-extract-plugin
export var awesome = "locator-module--awesome--c2be2";
export var bold = "locator-module--bold--80c59";
export var center = "locator-module--center--680c4";
export var h1 = "locator-module--h1--45c7f";
export var h1Bold = "locator-module--h1--bold--66a86";
export var h1Uc = "locator-module--h1--uc--ad0fe";
export var h2 = "locator-module--h2--93f04";
export var h2Bold = "locator-module--h2--bold--9c727";
export var h2Uc = "locator-module--h2--uc--0bae8";
export var h3 = "locator-module--h3--dc72c";
export var h3Bold = "locator-module--h3--bold--e728f";
export var h3Uc = "locator-module--h3--uc--1ba91";
export var h4 = "locator-module--h4--75304";
export var h4Bold = "locator-module--h4--bold--fec85";
export var h4Uc = "locator-module--h4--uc--2f84f";
export var linkUnderline = "locator-module--link--underline--fa77d";
export var map = "locator-module--map--d7bd6";
export var mapContainer = "locator-module--mapContainer--709c4";
export var mapPanel = "locator-module--mapPanel--7a3ff";
export var mapPanelSearch = "locator-module--mapPanelSearch--c2c92";
export var mapPanelSearchClear = "locator-module--mapPanelSearchClear--442ea";
export var mapPanelSearchInput = "locator-module--mapPanelSearchInput--a20d2";
export var microtext = "locator-module--microtext--aa615";
export var microtextBold = "locator-module--microtext--bold--40aeb";
export var microtextUc = "locator-module--microtext--uc--625e0";
export var nanotext = "locator-module--nanotext--c0804";
export var storeDetails = "locator-module--storeDetails--9cf0c";
export var strikethrough = "locator-module--strikethrough--b8220";
export var strikethroughBold = "locator-module--strikethrough--bold--6e617";
export var strikethroughLg = "locator-module--strikethrough--lg--0d0dd";
export var strikethroughSm = "locator-module--strikethrough--sm--0023d";
export var subtext = "locator-module--subtext--763f2";
export var subtextBold = "locator-module--subtext--bold--660d6";
export var subtextUc = "locator-module--subtext--uc--346bc";
export var text = "locator-module--text--2ff98";
export var textBold = "locator-module--text--bold--d78ba";
export var textUc = "locator-module--text--uc--c90e7";
export var titleL = "locator-module--title-l--eb12a";
export var titleM = "locator-module--title-m--0c3d9";
export var titleS = "locator-module--title-s--2c30d";
export var titleXl = "locator-module--title-xl--10289";
export var titleXs = "locator-module--title-xs--ccd31";
export var titleXxs = "locator-module--title-xxs--00f70";
export var uc = "locator-module--uc--46d2f";
export var underline = "locator-module--underline--af4ff";