import { graphql } from "gatsby";
import { useState, useEffect, useRef, useCallback } from "react";
import { GoogleMap, useLoadScript, Marker, InfoWindow } from "@react-google-maps/api";

import { ComponentType } from "../../constants/ComponentType";
import ALCustomLandingPage from "../../components/al_components/ALCustomLandingPage";
import RichTextContentful from "../../components/RichTextContentful";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import IconClose from "../../components/icons/svgs/close-round-lg.svg";
import * as Styles from "./locator.module.scss";

// Place Id finder:
// https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder

const icons = {
  "Ana Luisa Store": {
    url: "https://cdn.shopify.com/s/files/1/2579/7674/files/Retail_Stores_12.png",
    scaledSize: { width: 30, height: 36 },
  },
  Retailer: {
    url: "https://cdn.shopify.com/s/files/1/2579/7674/files/Wholesale_Partners_12.png",
    scaledSize: { width: 30, height: 36 },
  },
};

function StoreDetails({ store }) {
  return (
    <div className={Styles.storeDetails}>
      <h3>{store.name}</h3>
      <RichTextContentful
        input={store?.description?.raw}
        prefixClassName="lptext"
        showSEOLinks={false}
      />
    </div>
  );
}

const libraries = ["places"]; // Define libraries outside the component to avoid reloading issues
const defaultCenter = {
  lat: 37.0902,
  lng: -95.7129,
};

function sortStores(stores) {
  return stores.sort((a, b) => {
    // Rule 1: "AL Store" types come first
    if (a.type === "AL Store" && b.type !== "AL Store") {
      return -1;
    }
    if (a.type !== "AL Store" && b.type === "AL Store") {
      return 1;
    }

    // If both are "Other" type, sort by tierLevel
    if (a.type === "Other" && b.type === "Other") {
      // Extract tier numbers for comparison
      const tierA = parseInt(a.tierLevel[0].replace("Tier ", ""), 10);
      const tierB = parseInt(b.tierLevel[0].replace("Tier ", ""), 10);
      return tierA - tierB; // Sort in ascending order (Tier 1, Tier 2, etc.)
    }

    // If both are "AL Store" or don't match any rule, maintain original order
    return 0;
  });
}

function StoreLocatorPage({ data }) {
  const { stores, postMap, preMap } = data.storeLocatorData.edges[0].node;
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyBC5aqpzUTGgmxnbVnS-nhdlmX_Qsqcw7Q",
    libraries,
  });

  const [selectedStore, setSelectedStore] = useState(null);
  const [visiblePlaces, setVisiblePlaces] = useState(stores);
  const [searchValue, setSearchValue] = useState("");
  const [isSearchActive, setIsSearchActive] = useState(false);

  const mapRef = useRef();

  const handleMarkerClick = (store) => {
    setSelectedStore(store);
  };

  // Function to parse JSON from description.raw
  const parseDescription = (rawDescription) => {
    try {
      const parsedData = JSON.parse(rawDescription);
      // Extract text from the parsed JSON structure
      let descriptionText = "";

      const extractText = (node) => {
        if (node.nodeType === "text" && node.value) {
          descriptionText += `${node.value} `;
        }

        if (node.content && Array.isArray(node.content)) {
          node.content.forEach((childNode) => extractText(childNode));
        }
      };

      if (parsedData.content && Array.isArray(parsedData.content)) {
        parsedData.content.forEach((node) => extractText(node));
      }
      return descriptionText.trim();
    } catch (error) {
      console.error("Error parsing description:", error);
      return "";
    }
  };

  const handleSearch = useCallback(() => {
    if (!searchValue.trim()) {
      setIsSearchActive(false);
      // If search is cleared, show only the places within the map bounds
      handleIdle();
      return;
    }

    setIsSearchActive(true);

    const searchTerm = searchValue.toLowerCase();
    const filteredStores = stores.filter((store) => {
      const nameMatch = store.name.toLowerCase().includes(searchTerm);
      let descriptionMatch = false;
      if (store.description && store.description.raw) {
        const descriptionText = parseDescription(store.description.raw);
        descriptionMatch = descriptionText.toLowerCase().includes(searchTerm);
      }

      return nameMatch || descriptionMatch;
    });

    setVisiblePlaces(filteredStores);
  }, [searchValue, stores]);

  const handleIdle = useCallback(() => {
    if (mapRef.current && !isSearchActive) {
      const bounds = mapRef.current.getBounds();

      // Filter places within bounds
      const filteredPlaces = stores.filter((place) => {
        const position = new window.google.maps.LatLng(place.latitude, place.longitude);
        return bounds.contains(position);
      });

      setVisiblePlaces(sortStores(filteredPlaces));
    }
  }, [isSearchActive, stores]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      handleSearch();
    }, 300);
    return () => clearTimeout(debounceTimeout);
  }, [searchValue, handleSearch]);

  const handleClearSearch = () => {
    setSearchValue("");
    setIsSearchActive(false);
    // Reset to map-based filtering immediately
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      if (bounds) {
        const filteredPlaces = stores.filter((place) => {
          const position = new window.google.maps.LatLng(place.latitude, place.longitude);
          return bounds.contains(position);
        });
        setVisiblePlaces(sortStores(filteredPlaces));
      }
    }
  };

  // Handle search input changes
  const handleSearchInputChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    // Immediately reset to map-based filtering when search is cleared
    if (!value.trim()) {
      setIsSearchActive(false);
      if (mapRef.current) {
        // Reset to map-based filtering without waiting for debounce
        const bounds = mapRef.current.getBounds();
        if (bounds) {
          const filteredPlaces = stores.filter((place) => {
            const position = new window.google.maps.LatLng(place.latitude, place.longitude);
            return bounds.contains(position);
          });
          setVisiblePlaces(sortStores(filteredPlaces));
        }
      }
    }
  };

  const handleMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  if (loadError) return <div>Error loading maps</div>;

  return (
    <>
      {/* pre-map content */}
      {preMap && (
        <ALCustomLandingPage data={preMap || {}} componentType={ComponentType.LANDING_PAGE} />
      )}

      {isLoaded ? (
        <div className={Styles.map}>
          <div className={Styles.mapPanel}>
            <div className={Styles.mapPanelSearch}>
              <input
                type="text"
                placeholder="Search for a location"
                className={Styles.mapPanelSearchInput}
                value={searchValue}
                onChange={handleSearchInputChange}
              />
              <button
                className={Styles.mapPanelSearchClear}
                onClick={handleClearSearch}
                aria-label="Clear search"
                disabled={!searchValue.trim()}
              >
                <IconClose fill="none" stroke="#2D2927" width="40px" />
              </button>
            </div>
            {visiblePlaces.map((place) => (
              <StoreDetails key={`panel-${place.contentful_id}`} store={place} />
            ))}
          </div>

          <div className={Styles.mapContainer}>
            <GoogleMap
              mapContainerStyle={{ width: "100%", height: "60vh" }}
              zoom={5}
              center={defaultCenter}
              onLoad={handleMapLoad}
              onIdle={handleIdle}
            >
              {visiblePlaces.map((store) => (
                <Marker
                  key={`marker-store-locator-${store.contentful_id}`}
                  position={{
                    lat: parseFloat(store.latitude),
                    lng: parseFloat(store.longitude),
                  }}
                  icon={icons[store.type]}
                  onClick={() => handleMarkerClick(store)}
                />
              ))}

              {selectedStore && (
                <InfoWindow
                  position={{
                    lat: parseFloat(selectedStore.latitude),
                    lng: parseFloat(selectedStore.longitude),
                  }}
                  onCloseClick={() => setSelectedStore(null)}
                >
                  <StoreDetails store={selectedStore} />
                </InfoWindow>
              )}
            </GoogleMap>
          </div>
        </div>
      ) : (
        <div>Loading Maps...</div>
      )}

      {/* post-map content */}
      {postMap && <ALCustomLandingPage data={postMap} componentType={ComponentType.LANDING_PAGE} />}
    </>
  );
}

export default withALErrorBoundary({
  name: "StoreLocatorPage",
  priority: "P1",
})(StoreLocatorPage);

export const query = graphql`
  query {
    storeLocatorData: allContentfulStoreLocator(filter: { node_locale: { eq: "en-US" } }) {
      edges {
        node {
          stores {
            contentful_id
            name
            type
            tierLevel
            latitude
            longitude
            description {
              raw
            }
          }
          preMap {
            id
            blocks {
              ...ContentfulLandingPageBlocks
            }
          }
          postMap {
            id
            blocks {
              ...ContentfulLandingPageBlocks
            }
          }
        }
      }
    }
  }
`;
